<template>
    <div class="elmain">
        <el-button type="success" @click="add">新增分组</el-button>
        <el-table :data="list" border style="width: 100%;margin-top: 20px;">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="sortnum" label="排序"></el-table-column>
            <el-table-column prop="name" label="组名" ></el-table-column>
            <el-table-column prop="id" label="分组ID" ></el-table-column>
            <el-table-column prop="manager_user_names" label="部门主管"></el-table-column>
            <el-table-column prop="devices_count" label="设备数量"></el-table-column>
            <el-table-column prop="plan_work_time" label="预计工作时间"></el-table-column>
            <el-table-column label="操作" width="250" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" @click="showStaffList(scope.row)">员工管理</el-button>
                    <el-button @click="handleClick(scope.row)" type="warning" size="small">编辑</el-button>
                    <el-button type="danger" @click="deleteg(scope.row)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">

            <el-pagination v-if="total>10" style=" width: 100%;margin: 20px auto;" :current-page.sync="page"
                           layout="total,prev, pager, next" @size-change="getlist" @current-change="getlist"
                           :total="total"></el-pagination>
        </div>

        <!-- 修改框 -->
        <el-dialog title="分组信息" :visible.sync="dialogFormVisible">
            <el-form :model="dialogData">
                <el-form-item label="分组名称" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.name" autocomplete="off"></el-input>
                </el-form-item>
<!--                <el-form-item label="部门主管" :label-width="formLabelWidth">-->
<!--                    <el-select v-model="dialogData.manager_user_ids" multiple placeholder="请选择" style="width: 100%;">-->
<!--                        <el-option-->
<!--                            v-for="item in staffList"-->
<!--                            :key="item.id"-->
<!--                            :label="item.name"-->
<!--                            :value="item.id">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="排序" :label-width="formLabelWidth">
                    <el-input type="number" placeholder='越大越靠前' v-model="dialogData.sortnum"
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="工作时间/小时" :label-width="formLabelWidth">
                    <el-input type="number" placeholder='预计工作时间' v-model="dialogData.plan_work_time"
                              autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog v-if="staffListShow" :visible.sync="staffListShow" width="85%">
            <staff :currCompanyGroupId="currId"></staff>
        </el-dialog>
    </div>
</template>

<script>
import {group, addgroup, putgroup, deletegroup, getstaff} from "@/service/api";
import staff from "@/views/staff/index";
export default {
    components:{
        staff
    },
    data() {
        return {
            tableData: [],
            page: 1,
            list: [],
            total: 0,
            dialogFormVisible: false,
            formLabelWidth: '120px',
            dialogData: {
                name: '',
                sortnum: 0,
                plan_work_time: 24
                // manager_user_ids: ''
            },
            type: '',
            staffList: [],
            staffListShow: false,
            currId: ''
        };
    },
    methods: {
        getStaffListData() {
            let data = {
                limit: 999,
                page: 1,
                name: '',
                company_group_id: ''
            };
            getstaff(data).then(res => {
                this.staffList = res.data.list;
                // this.total = res.data.total;
            });
        },
        handleClick(row) {
            this.dialogFormVisible = true;
            this.type = "put";
            this.dialogData = {
                name: row.name,
                sortnum: row.sortnum,
                id: row.id
                // manager_user_ids: row.manager_user_ids
            };
        },
        getlist() {
            group({limit: 100, page: this.page}).then(res => {
                this.list = res.data.list;
                this.list.forEach(function (value) {
                    const manger_user_ids = [];
                    const manager_user_names = [];
                    value.managers.forEach(function (item){
                        manager_user_names.push(item.name);
                        manger_user_ids.push(item.id);
                    });
                    value.manager_user_ids = manger_user_ids;
                    value.manager_user_names = manager_user_names.join(',');
                });
                this.total = res.data.total;
            });
        },
        add() {
            this.dialogFormVisible = true;
            this.type = "add";
            this.dialogData = {
                name: '',
                sortnum: 0,
                plan_work_time: 24
                // manager_user_ids: ''
            };

        },
        deleteg(i) {
            console.log(i);
            if (i.devices_count !== 0) {
                this.$message.error('此分组还有设备无法删除');
                return;
            }
            let that = this;
            this.$confirm('此操作将永久删除该分组, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id
                };
                deletegroup(data).then(res => {
                    if (res.code === 200) {
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        that.getlist();
                    }

                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        submit() {
            if (this.dialogData.name === '') {
                this.$message.error('请输入分组名');
                return;
            }
            if (this.dialogData.sortnum === '') {
                this.$message.error('排序不能为空');
                return;
            }
            if (this.dialogData.plan_work_time === '') {
                this.$message.error('工作时间不能为空');
                return;
            }
            if (this.dialogData.plan_work_time > 24) {
                this.$message.error('工作时间不能大于24小时');
                return;
            }
            if (this.type === 'add') {
                addgroup(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }

                });
            }
            if (this.type === 'put') {

                putgroup(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }
                });
            }

        },

        showStaffList(row){
            this.staffListShow = true;
            this.currId = row.id;
        }
    },
    mounted() {
        this.getlist();
        this.getStaffListData();
    }
};
</script>
<style scoped>
.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .main-conent {
        margin: 10px;
    }

    .elmain[data-v-7ad48fd0] {
        padding: 10px;
    }

    .el-dialog {
        width: 80% !important;
    }
}
</style>
