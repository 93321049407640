<template>
    <div class="elmain">
        <el-button type="success" @click="add">新增人员</el-button>

        <el-input style="width: 400px;    margin-left: 80px;margin-right: 50px;" placeholder="请输入用户名称" v-model="name"
                  autocomplete="off"></el-input>
        <span style="    color: #909399;font-size: 14px;">所属部门：</span>
        <el-select style="margin-right: 50px;" v-model="company_group_id" placeholder="请选择所属部门">
            <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>
        <el-button type="primary" @click="cx">查询</el-button>
        <el-table :data="list" border style="width: 100%;margin-top: 20px;">
            <el-table-column prop="name" label="人员名称"></el-table-column>
            <el-table-column prop="code" label="人员编号"></el-table-column>
            <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
<!--            <el-table-column prop="company_group_id" label="所属部门" :formatter="swgroup"></el-table-column>-->
            <el-table-column prop="is_manage" label="所属角色" width="100" :formatter="formatIsMange"></el-table-column>
            <el-table-column prop="manager_group_name" label="关联部门" width="240"></el-table-column>
            <!-- <el-table-column prop="wx_name" label="微信绑定">
                <template slot-scope="{row}">
                    {{ row.wx_openid ? '是' : '否' }}
                </template>
            </el-table-column> -->
            <el-table-column prop="wx_name" label="关注公众号">
                <template slot-scope="{row}">
                    {{ row.is_subscribe == 1 ? '是' : '否' }}
                </template>
            </el-table-column>

            <el-table-column prop="created_at" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="250" fixed="right">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.wx_openid" @click="unbindWx(scope.row)" type="primary" size="small">微信解绑</el-button>
                    <el-button @click="handleClick(scope.row)" type="warning" size="small">编辑</el-button>
                    <el-button type="danger" @click="deleteg(scope.row)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" style="    margin-top: 10px;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit"
                           layout="sizes, prev, pager, next" :total="total">
            </el-pagination>

        </div>

        <!-- 修改框 -->
        <el-dialog title="人员信息" :visible.sync="dialogFormVisible" append-to-body>
            <el-form :model="dialogData">
                <el-form-item label="人员名称" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.phone" autocomplete="off"></el-input>
                </el-form-item>
                <!--                <el-form-item label="人员角色" :label-width="formLabelWidth">-->
                <!--                    <el-select v-model="dialogData.role" placeholder="请选择">-->
                <!--                        <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item label="人员编号" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="角色" :label-width="formLabelWidth">
                    <el-select v-model="dialogData.is_manage" placeholder="请选择">
                        <el-option
                            v-for="item in roleMap"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="dialogData.is_manage == 0" label="所属部门" :label-width="formLabelWidth">
                    <el-select v-model="dialogData.company_group_id" placeholder="请选择">
                        <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="dialogData.is_manage == 1"   label="管理部门" :label-width="formLabelWidth">
                    <el-select v-model="dialogData.manager_group_ids" placeholder="请选择" multiple>
                        <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getstaff, addstaff, putstaff, deletestaff, group} from "@/service/api";

export default {
    name: 'Staff',
    props: {
        currCompanyGroupId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            tableData: [],
            page: 1,
            name: '',
            limit: 10,
            list: [],
            total: 0,
            dialogFormVisible: false,
            formLabelWidth: '120px',
            dialogData: {
                name: '',
                code: '',
                company_group_id: '',
                role: '',
                phone: '',
                password: '',
                is_manage: 0,
                manager_group_ids: ''
            },
            type: '',
            grouplist: [],
            company_group_id: '',
            roleMap: [
                {value: 0, label: '普通员工'},
                {value: 1, label: '主管'},
                {value: 9, label: '老板'}
            ]
        };
    },
    methods: {
        formatIsMange(row) {
            let text = row.is_manage;
            this.roleMap.forEach(item => {
                if(item.value === row.is_manage){
                    text = item.label;
                }
            });
            return text;
        },
        swgroup(row) {
            let text = '';
            this.grouplist.forEach(item => {
                if (item.id === row.company_group_id) {
                    text = item.name;
                }

            });
            return text;

        },
        swRole(row) {
            let text = '';
            this.roleList.forEach(item => {
                if (item.id === row.role) {
                    text = item.name;
                }

            });
            return text;

        },
        getgroup() {
            group({limit: 100, page: 1}).then(res => {
                this.grouplist = res.data.list;
            });
        },
        cx() {
            this.page = 1;
            this.getlist();
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.limit = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getlist();
        },
        handleClick(row) {
            this.dialogFormVisible = true;
            this.type = "put";
            this.dialogData = {
                name: row.name,
                code: row.code,
                company_group_id: row.manager_group_ids[0],
                id: row.id,
                role: row.role,
                phone: row.phone,
                is_manage: row.is_manage,
                manager_group_ids: row.manager_group_ids
            };
        },
        getlist() {
            let data = {
                limit: this.limit,
                page: this.page,
                name: this.name,
                company_group_id: this.company_group_id
            };
            console.log(data);
            getstaff(data).then(res => {
                this.list = res.data.list;
                this.list.forEach(function (value) {
                    const manager_group_name = [];
                    const manager_group_ids = [];
                    value.manage_groups.forEach(function (item) {
                        manager_group_name.push(item.name);
                        manager_group_ids.push(item.id);
                    });
                    value.manager_group_ids = manager_group_ids;
                    value.manager_group_name = manager_group_name.join(',');
                });
                this.total = res.data.total;
            });
        },
        add() {
            this.dialogFormVisible = true;
            this.type = "add";
            this.dialogData = {
                name: '',
                code: '',
                company_group_id: '',
                is_manage: 0,
                manager_group_ids: ''
            };

        },
        deleteg(i) {
            console.log(i);
            let that = this;
            this.$confirm('此操作将永久删除该人员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id
                };
                deletestaff(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });

                    }

                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        unbindWx(i) {
            let that = this;
            this.$confirm('此操作将该人员微信相关信息解绑, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id,
                    wx_openid: null,
                    wx_name: null,
                    wx_avatar: null,
                    wx_unionid: null,
                    wx_official_openid: null,
                    is_subscribe: 0
                };
                putstaff(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });

                    }

                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },

        submit() {
            if (this.dialogData.name === '') {
                this.$message.error('请输入人员名');
                return;
            }
            if (this.dialogData.code === '') {
                this.$message.error('请输入人员编号');
                return;
            }
            if(this.dialogData.is_manage === 0){
                this.dialogData.manager_group_ids = [this.dialogData.company_group_id];
            }
            if (this.dialogData.is_manage === 1) {
                this.dialogData.company_group_id = '';
            }
            if (this.type === 'add') {
                addstaff(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }

                });
            }
            if (this.type === 'put') {

                putstaff(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }
                });
            }

        }
    },
    mounted() {
        this.getgroup();
        this.getlist();
    },
    created() {
        this.company_group_id = this.currCompanyGroupId ? this.currCompanyGroupId : '';
    }
};
</script>
<style scoped>
.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .main-conent {
        margin: 10px;
    }

    .elmain[data-v-7ad48fd0] {
        padding: 10px;
    }

    .el-dialog {
        width: 80% !important;
    }
}
</style>
